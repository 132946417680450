import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from 'react-bootstrap';
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { VERIFY_PASSWORD_URL, FORGOT_PASSWORD_URL } from '../../../helper/url_helper';
import { useSelector, useDispatch } from "react-redux";
import { setData as setForgotData } from '../../../store/forgot/action';

const Verification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const forgotState = useSelector((state) => state.forgot);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(120); // Initial time left in seconds
  const [refereshOtp, setRefereshOtp] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]); // Initial state for OTP inputs
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]



  useEffect(() => {
    // if not signup then rediret to signup
  }, []);
  // Function to decrement time left every second
  useEffect(() => {
    setTimeLeft(120);
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0)); // Ensure time doesn't go below 0
    }, 1000);
    // Clear interval when component unmounts or timeLeft reaches 0
    return () => clearInterval(intervalId);
  }, [refereshOtp]);

  // Format seconds into minutes and seconds
  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  function refereshOtpHandler() {
    if ((timeLeft > 0)) {
      return;
    }
    refershOtpSubmit();
    setRefereshOtp(!refereshOtp);
  }

  // submit and move on next page
  async function refershOtpSubmit() {
    try {
      let res = await api.post(FORGOT_PASSWORD_URL, { "email": forgotState.email });
      if (res.data.status === "SUCCESS") {
        toast.success("OTP resended");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  function onChangeHandler(e, index) {
    const { value } = e.target;

    // Validate input to allow only integers
    if (!/^\d*$/.test(value)) {
      // If input is not an integer, return without updating state
      return;
    }

    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    // Handle backspace
    if (e.nativeEvent.inputType === "deleteContentBackward" && !value) {
      if (index > 0) {
        // Move focus to the previous input field
        inputRefs[index - 1].current.focus();
      } else {
        // Cut the content of the current input field
        inputRefs[index].current.value = "";
      }
    } else if (value && index < otpInputs.length - 1) {
      // Move focus to the next input field if the current one is not empty
      inputRefs[index + 1].current.focus();
    }
  }


  async function submit() {
    try {
      let data = { "email": forgotState.email }
      data["otp"] = (otpInputs[0].toString()) + otpInputs[1] + otpInputs[2] + otpInputs[3];
      // assemble otp wi
      let res = await api.post(VERIFY_PASSWORD_URL, data);
      if (res.data.status === "SUCCESS") {
        dispatch(setForgotData(res.data.payload.forgot_key, "forgot_key"));
        history.push("reset-password");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <>
      <div className="screen">
        <div className="main-content bg-white">
          <div className="d-flex align-items-center flex-column justify-content-center verification-main">
            <img src="images/verification-banner.png" />
            <h2 className="mt-3 medium-title">Verify your email</h2>
            <div className="d-flex otp-mail mb-30">
              <p className=".small-p">Enter the OTP sent to</p>
              <a href="">- {forgotState.email}</a>
            </div>
            <div>
              <Form className="d-flex align-items-center gap-3 justify-content-between verification-code">
                {otpInputs.map((input, index) => {
                  return <>
                    <Form.Group className="mb-3" key={index} controlId="exampleForm.ControlInput1">
                      <Form.Control onChange={(e) => { onChangeHandler(e, index) }} className="custom-form-control grey-bg code-box" ref={inputRefs[index]} value={input} size="lg" type="text" />
                    </Form.Group>
                  </>
                })}
                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Control className="custom-form-control grey-bg code-box" size="lg" type="text" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                  <Form.Control className="custom-form-control grey-bg code-box" size="lg" type="text" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                  <Form.Control className="custom-form-control grey-bg code-box" size="lg" type="text" />
                </Form.Group> */}
              </Form>
            </div>
            <span className=".span my-4 time-count">{formatTime(timeLeft)} Sec</span>
            <div className="d-flex otp-mail">
              <p className="me-2">Don’t receive code? </p>
              <span className={(timeLeft > 0) ? "font-dark-grey" : "font-blue"} onClick={refereshOtpHandler}> Re-send</span>
            </div>
            <div className="mt-30 w-100">
              <Button className="signin-btn" onClick={submit}>Verify</Button>
            </div>
            {/* <Button className="text-center primary">Verify</Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
