import React, { useState, useEffect } from 'react';
import { PendingOrderBox } from '../../../components/orders/smallComponents.jsx';
import { ExecutedOrderBox } from '../../../components/orders/smallComponents.jsx';
import { TradeBox } from '../../../components/orders/smallComponents.jsx';
import { Card, Tab, Nav, /*Dropdown, DropdownButton*/ } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchOrder, fetchTrades, changeCurrency } from '../../../store/order/action.jsx';
import { Footer } from '../../../components/common/common.jsx';
import { ModalBox } from '../../../components/orders/smallComponents.jsx';
import { CANCEL_ORDER_URL } from '../../../helper/url_helper.js';
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { EmptyPageImage } from '../../../components/common/common.jsx';

export default function PendingOrders() {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const orderState = useSelector((state) => state.order);
    // const socketState = useSelector((state) => state.socket);
    const [refereshKey, setRefereshKey] = useState(1);
    const [exitModalStatus, setExitModalStatus] = useState(false);
    const [activeOrder, setActiveOrder] = useState(0);

    useEffect(() => {
        dispatch(fetchOrder(loginState.jwttoken));
        dispatch(fetchTrades(loginState.jwttoken))
    }, [])

    useEffect(() => {
        setRefereshKey(refereshKey + 1);
    }, [orderState]);

    function changeCurrencyHandler(currency) {
        dispatch(changeCurrency(currency))
    }

    function openModalHandler(order) {
        setActiveOrder(order);
        setExitModalStatus(true);
    }

    async function cancelOrderHandler() {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let data = {
                "order_id": activeOrder.id
            };
            let res = await api.post(CANCEL_ORDER_URL, data);
            if (res.data.status === "SUCCESS") {
                toast.success("Order canceled");
                setExitModalStatus(false);
                dispatch(fetchOrder(loginState.jwttoken))
            } else {
                toast.error(res.data.message);
                setExitModalStatus(false);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            setExitModalStatus(false);
        }
    }

    return <>
        <div className="screen" >
            <ModalBox show={exitModalStatus} handleYes={cancelOrderHandler} onHide={() => { setExitModalStatus(false) }} />

            <div className="main-content bg-light">
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Orders</span>
                            </div>
                            <div className="col-5">
                                <Tab.Container>
                                    <Nav className="d-flex justify-content-end orders-tab" variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="inr" className={(orderState.currency === "INR") ? "active" : ""} onClick={() => { changeCurrencyHandler("INR") }}>INR</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forex" className={(orderState.currency === "USD") ? "active" : ""} onClick={() => { changeCurrencyHandler("USD") }}>Forex</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    {/* <Tab.Content>
                                        <Tab.Pane eventKey="inr">

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forex">

                                        </Tab.Pane>
                                    </Tab.Content> */}
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Tab.Container defaultActiveKey="pending" >
                        <div className="d-flex align-items-center justify-content-between mb-16">
                            <Nav className="d-flex w-100 justify-content-between watchlist-tab mb-0" variant="tabs">
                                <Nav.Item>
                                    <Nav.Link className='px-4' eventKey="pending">Pending</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='px-4' eventKey="executed" >Executed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='px-4' eventKey="trades">Trades</Nav.Link>
                                </Nav.Item>

                            </Nav>
                            {/* <DropdownButton className="executed-dropdown" id="dropdown-basic-button" title="Day 1">
                                <Dropdown.Item href="#/action-1">Day 2</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Day 3</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Day 4</Dropdown.Item>
                            </DropdownButton> */}
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="pending">
                                {((Object.keys(orderState.orders.pending[orderState.currency]).length === 0)) &&
                                    <EmptyPageImage />
                                }
                                {orderState.orders.pending[orderState.currency].map((order) => {
                                    return <>
                                        <PendingOrderBox order={order} key={order.id} openModalHandler={openModalHandler} />
                                    </>
                                })}
                            </Tab.Pane>
                            <Tab.Pane eventKey="executed">
                                {((Object.keys(orderState.orders.executed[orderState.currency]).length === 0)) &&
                                    <EmptyPageImage />
                                }
                                {orderState.orders.executed[orderState.currency].map((order) => {
                                    return <>
                                        <ExecutedOrderBox order={order} key={order.id} />
                                    </>
                                })}

                            </Tab.Pane>
                            <Tab.Pane eventKey="trades">
                                {((Object.keys(orderState.trades[orderState.currency]).length === 0)) &&
                                    <EmptyPageImage />
                                }
                                {orderState.trades[orderState.currency].map((trade) => {
                                    return <>
                                        <TradeBox trade={trade} key={trade.id} />
                                    </>
                                })}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>

            <Footer />
        </div>
    </>
}