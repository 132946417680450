import { SET_FORGOT_DATA } from "./actionTypes";
let initialState = {
  "email": "",
  "forgot_key": ""
};

const forgot = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORGOT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    default:
      return state;
  }
};
export default forgot;