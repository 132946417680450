// import { RoundedButton } from '../../components/common/common.jsx';
// import { IndexBox, WatchlistBox } from '../../components/watchlist/smallComponents.jsx'
import { useState } from 'react';
import { PositionBox2 } from '../../components/portfolio/smallComponents.jsx';
import { Card, Tab, Nav, Modal, Button } from 'react-bootstrap';

export default function PortfolioPopup() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <>
        <div className="screen mb-3">
            <div className="main-content bg-light px-0">
                <div className="px-3">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Portfolio Popup</span>
                            </div>
                            <div className="col-5">
                                <Tab.Container id="tabs-example" defaultActiveKey="forex">
                                    <Nav className="d-flex justify-content-end orders-tab" variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="inr">INR</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forex">Forex</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="inr">

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forex">

                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="px-3">
                    <Card className="border-0 p-0 mb-3">
                        <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                            <span className="font-green font-l d-block">+91730.00</span>
                            <span className="font-dark-grey font-m">Total P&L</span>
                        </Card.Body>
                    </Card>
                </div>
                <div className="px-3">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <span className="font-m fw-semibold">Trading Balance</span>
                            <div className="label label-blue font-s">View <i className="font-s fa-regular fa-eye"></i></div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="trading-balance-card bg-white p-3 d-flex">
                    <div className="text-center flex-grow-1"><span className='font-m'>722774.64</span><br /><span className="font-m font-dark-grey">Ledger</span></div>
                    <div className="border-left-1 flex-grow-1 mx-2"></div>
                    <div className="text-center flex-grow-1"><span className='font-m'>481170.64</span><br /><span className=" font-m font-dark-grey">Margin Available</span></div>
                    <div className="border-left-1 flex-grow-1 text-center mx-2"></div>
                    <div className="text-center flex-grow-1"><span className='font-m'>241604.00</span><br /><span className="font-m font-dark-grey">Margin Used</span></div>
                </div>
                <div className="d-flex justify-content-between bg-blue-light py-11 px-3">
                    <div><span className="font-m font-dark-grey me-2">M2M:</span><span className='font-m font-green'>810694</span></div>
                    <div><span className="font-m font-dark-grey me-2">Holding:</span><span className='font-m font-red'>810694</span></div>
                </div>
                <div className="px-3 pt-4">
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <span className="font-m fw-semibold">Positions</span>
                            <div className=""><Button className="closeall-btn" onClick={handleShow}>Close All  <i clasName="font-s fa-regular fa-eye"></i></Button></div>
                        </Card.Body>
                    </Card>
                    <Modal centered className="theme-popup" show={show} onHide={handleClose}>
                        {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
                        <Modal.Body className="p-4 pb-0 text-center mb-3">
                            <img src="images/explanation-mark.svg" alt="explanation-mark" className="pb-20" />
                            <div className="font-ml fw-semibold mb-2">Are you sure ?</div>
                            <p className="font-m font-dark-grey mb-0">You won't to close all positions</p>
                        </Modal.Body>
                        <Modal.Footer className="border-0 pb-4 pt-0 d-flex justify-content-center">
                            <Button className="yes-btn me-3" onClick={handleClose}>
                                Yes
                            </Button>
                            <Button className="cancle-btn" onClick={handleClose}>
                                Cancle
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="px-3">
                    <PositionBox2 />


                </div>
            </div >
            <div className="footer">
                <Tab.Container id="tabs-example" defaultActiveKey="portfolio">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div >
    </>
}