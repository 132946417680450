import React from "react";
import { Tab, Nav, Form, Col, Card, Button, Row } from "react-bootstrap";

export default function WatchlistSell() {
  return (
    <div className="screen mb-3">
      <div className="main-content p-0 bg-light">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <span className="d-block font-m fw-semibold d-flex align-items-center"><img className="me-2" src="images/left-arrow.svg" alt="left-arrow"></img>NATURALGAS<span className="label-white font-s text-black ms-2">CDS</span></span>
            <span className="font-s font-dark-grey ms-3">Natural Gold</span>
          </div>
          <div className="text-end">
            <span className="d-block font-ml font-green fw-semibold">1730.00</span>
            <span className="font-s font-dark-grey">+3.90 (+1.59%)</span>
          </div>
        </div>
        <div className="p-3 d-flex justify-content-between bg-white">
          <Col xs={5}>
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="custom-label">Price</Form.Label>
                <Form.Control className="custom-form-control border w-100" type="email" />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={6} className="d-flex align-items-end">
            <Tab.Container id="tabs-example" defaultActiveKey="forex">
              <Nav className="d-flex justify-content-end watchlistbuy-tab red" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="inr">LIMIT</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="forex">MARKET</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="inr">

                </Tab.Pane>
                <Tab.Pane eventKey="forex">

                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </div>
        <div className="p-3 bg-white">
          <Row className="g-2">
            <Col xs={6}>
              <Form>
                <Form.Label className="custom-label text-black">Qty</Form.Label>
                <div class="cart-left-side">
                  <button class="button cartplus red">+</button>
                  <Form.Control className="input border" type="text" placeholder="5" />
                  <button class="button cartminus red">–</button>
                </div>
              </Form>
            </Col>
            <Col xs={6}>
              <Form>
                <Form.Label className="custom-label text-black">Lots</Form.Label>
                <div class="cart-left-side">
                  <button class="button cartplus red">+</button>
                  <Form.Control className="input border" type="text" placeholder="0.60" />
                  <button class="button cartminus red">–</button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="px-3 bg-white pb-3">
          <Card className="border-0 p-0 bg-light">
            <Card.Body className="d-flex justify-content-center align-items-center flex-column">
              <span className="text-black">Available Balance :$7000</span>
            </Card.Body>
          </Card>
        </div>
        <div className="px-3 gap-2 d-flex justify-content-between pb-20 bg-white">
          <Card className="border-0 p-0 w-50">
            <Card.Body className="label-red d-flex justify-content-center align-items-center flex-column">
              <span className="text-black font-s">Intraday : 2156.00</span>
            </Card.Body>
          </Card>
          <Card className="border-0 p-0 w-50">
            <Card.Body className="label-red d-flex justify-content-center align-items-center flex-column">
              <span className="text-black font-s">Over night : 56400.00</span>
            </Card.Body>
          </Card>
        </div>
        <div className="px-3 pb-3 bg-white mb-4">
          <Button className="watchlistbuy-btn red w-100">BUY</Button>
        </div>
        <div className="py-3 bg-white">
          <Row className="g-0 align-items-center">
            <Col xs={4} className="mb-30">
              <div className="text-center">
                1
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                2
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                3
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                4
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                5
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                6
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                7
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                8
              </div>
            </Col>
            <Col xs={4} className="mb-30">
              <div className="text-center">
                9
              </div>
            </Col>
            <Col xs={4}>
              <div className="text-center">
                .-
              </div>
            </Col>
            <Col xs={4}>
              <div className="text-center">
                0
              </div>
            </Col>
            <Col xs={4}>
              <div className="text-center">
                <img src="images/mobile-close.svg" alt="mobile-close" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div >
  );
};

