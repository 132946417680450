import { useState } from 'react';
import { Card, Button, Offcanvas, Modal, Table } from 'react-bootstrap';
export function PositionBox({ instrument, position, sheetChangingHandler }) {

    return <>
        {/* <Card className="border-0 p-0 mb-2" onClick={handleShow}>
            <Card.Body className="px-3 py-2 bg-active">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-green font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">NATURALGAS</span><br />
                        <span className="label-grey font-s text-black me-2">CDS</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">14 JUN 23 3:00</span><br />
                        <span className="font-m fw-semibold">CMP:275.45</span><br />
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-green">4600</span>
                    </div>
                </div>
            </Card.Body>
        </Card> */}
        <Card className="border-0 p-0 mb-2" onClick={() => { sheetChangingHandler(instrument, position) }}>
            <Card.Body className="px-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className={`${position.type === "buy" ? "label-green" : "label-red"} font-s me-2`}>QTY: {position.qty}</span> <span className="font-s font-dark-grey">AVG:{position.avg_price}</span><br />
                        <span className="font-m fw-semibold">{position.trading_symbol}</span><br />
                        <span className="label-grey font-s text-black me-2">{position.exchange}</span> <span className="font-s font-dark-grey">Margin Used:{position.used_margin}</span>
                    </div>
                    <div className="text-end">
                        <span className="d-block font-s font-dark-grey">14 JUN 23 3:00</span>
                        <span className="d-block font-m fw-semibold">CMP:{position.cmp}</span>
                        <span className="font-s font-dark-grey">P&L:</span><span className={`font-s ${position.active_profit_and_loss > 0 ? "font-green" : "font-red"}`}>{position.active_profit_and_loss}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>


    </>
}

export function ModalBox({ show, onHide, handleYes }) {
    return <>
        <Modal centered className="theme-popup" show={show} onHide={onHide}>
            {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
            <Modal.Body className="p-4 pb-0 text-center mb-3">
                <img src="images/explanation-mark.svg" alt="explanation-mark" className="pb-20" />
                <div className="font-ml fw-semibold mb-2">Are you sure ?</div>
                <p className="font-m font-dark-grey mb-0">You won't to close all positions</p>
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 pt-0 d-flex justify-content-center">
                <Button className="yes-btn me-3" onClick={handleYes}>
                    Yes
                </Button>
                <Button className="cancle-btn" onClick={onHide}>
                    Cancle
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}












export function PositionBox2() {

    return <>
        <Card className="border-0 p-0 mb-3">
            <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-green font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">NATURALGAS</span><br />
                        <span className="label-grey font-s text-black me-2">CDS</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">14 JUN 23 3:00</span><br />
                        <span className="font-m fw-semibold">CMP:275.45</span><br />
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-green">4600</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3">
            <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-red font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">SILVERLGASM</span><br />
                        <span className="label-grey font-s text-black me-2">NFO</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="d-block font-s font-dark-grey">14 JUN 23 3:00</span>
                        <span className="d-block font-m fw-semibold">CMP:300.00</span>
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-green">4800</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3">
            <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-green font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">NATURALGAS</span><br />
                        <span className="label-grey font-s text-black me-2">CDS</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">14 JUN 23 3:00</span><br />
                        <span className="font-m fw-semibold">CMP:275.45</span><br />
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-red">-4600</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

export function PositionBox3() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <>
        <Card className="border-0 p-0 mb-3" onClick={handleShow}>
            <Card.Body className="p-3 bg-active">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-green font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">NATURALGAS</span><br />
                        <span className="label-grey font-s text-black me-2">CDS</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">14 JUN 23 3:00</span><br />
                        <span className="font-m fw-semibold">CMP:275.45</span><br />
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-green">4600</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3">
            <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-red font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">SILVERLGASM</span><br />
                        <span className="label-grey font-s text-black me-2">NFO</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="d-block font-s font-dark-grey">14 JUN 23 3:00</span>
                        <span className="d-block font-m fw-semibold">CMP:300.00</span>
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-green">4800</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-3">
            <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="label-green font-s me-2">QTY:100</span> <span className="font-s font-dark-grey">AVG:18530</span><br />
                        <span className="font-m fw-semibold">NATURALGAS</span><br />
                        <span className="label-grey font-s text-black me-2">CDS</span> <span className="font-s font-dark-grey">Margin Used:24954</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">14 JUN 23 3:00</span><br />
                        <span className="font-m fw-semibold">CMP:275.45</span><br />
                        <span className="font-s font-dark-grey">P&L:</span><span className="font-s font-red">-4600</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Offcanvas className="watchlistbuysell-popup" placement="bottom" show={show} onHide={handleClose}>
            {/* <Offcanvas.Header closeButton>
            </Offcanvas.Header> */}
            {/*<Offcanvas.Body className="p-0 bg-light border-radius-top-20">
                <div className="px-3 py-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                            <span className="d-block font-m fw-semibold d-flex align-items-center">NATURALGAS<span className="label-white font-s text-black ms-2">CDS</span></span>
                            <span className="font-s font-red">Lots size : 1435.00</span>
                        </div>
                        <div className="text-end">
                            <span className="d-block font-ml font-green fw-semibold">1730.00</span>
                            <span className="font-s font-dark-grey">+3.90 (+1.59%)</span>
                        </div>
                    </div>
                    <div className="trading-balance-modal bg-white p-3 d-flex justify-content-between">
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Open</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">High</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Low</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Close</span><span className="font-m">59401.00</span></div>
                    </div>
                    <div>
                        <Table className="buysell-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>BUY PRICE</th>
                                    <th>SELL PRICE</th>
                                    <th>QTY</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red">249.50</div></td>
                                    <td>1747</td>
                                </tr>
                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red width-70">249.50</div></td>
                                    <td>4270</td>
                                </tr>
                                <tr>
                                    <td>0.123</td>
                                    <td><div className="graph width-50">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>1123</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-80">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-100">249.50</div></td>
                                    <td><div className="graph red width-100">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>3524354</td>
                                    <td className="text-center" colSpan={2}>TOTAL QUANTITY</td>
                                    <td>1563503</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="chart-button">
                        <Button className="chart-btn"> <img className="me-2" src="images/chart.svg" alt="chart" />Chart</Button>
                    </div>
                    <ul className="popup-lists">
                        <li>
                            <span className="font-m font-dark-grey">Volume</span>
                            <span className="font-m text-black">72,031</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Avg. traded price</span>
                            <span className="font-m text-black">26.08</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded quantity</span>
                            <span className="font-m text-black">20</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded at</span>
                            <span className="font-m text-black">2023-05-17  15:29:44</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last circuit</span>
                            <span className="font-m text-black">21.15</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Upper circuit</span>
                            <span className="font-m text-black">31.65</span>
                        </li>
                    </ul>
                    <div className="portfolio-modal-button">
                        <Button className="buy-btn">
                            BUY
                            <span className="d-block">@256.00</span>
                        </Button>
                        <Button className="sell-btn">
                            SELL
                            <span className="d-block">@256.00</span>
                        </Button>
                    </div>
                </div>


        </Offcanvas.Body> */}
            <Offcanvas.Body className="p-0 bg-light border-radius-top-20">
                <div className="d-flex justify-content-between align-items-center px-3 py-3 border-bottom-1">
                    <div className="">
                        <span className="d-block font-m fw-semibold d-flex align-items-center">
                            NATURALGAS
                        </span>
                        <span className="font-m font-dark-grey">
                            MCX<span className="ms-1 font-red font-m">4059.00</span>
                            <span className="ms-1 p-1 label-blue">NSE</span>
                        </span>
                    </div>
                </div>
                <div className="px-3 py-4">



                    {/* button mukavana 2 */}
                    <div className="transaction-buttons">
                        <Button className="deposit-btn me-3">Add</Button>
                        <Button className="withdraw-btn">Exit</Button>
                    </div>
                    <div>

                        <span className="d-block font-m mt-3 mb-3 fw-normal">Margin Used</span>
                        <Table striped className="portfolio-b">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>Invested</th>
                                    <th style={{ textAlign: 'center' }}>Current</th>
                                    <th style={{ textAlign: 'right' }}>P & L</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2715.00</td>
                                    <td style={{ textAlign: 'center' }}>3580.00</td>
                                    <td style={{ textAlign: 'right' }}>747</td>
                                </tr>
                                <tr>
                                    <td>QTY</td>
                                    <td colspan="2" style={{ textAlign: 'right' }}>43.00</td>
                                </tr>
                                <tr>
                                    <td>Avg. Price</td>
                                    <td colspan="2" style={{ textAlign: 'right' }}>64.93</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="trading-balance-modal bg-white p-3 d-flex justify-content-between">
                        <div className="text-center">
                            <span className="d-block font-m font-dark-grey">Open</span>
                            <span className="font-m">59401.00</span>
                        </div>
                        <div className="text-center">
                            <span className="d-block font-m font-dark-grey">High</span>
                            <span className="font-m">59401.00</span>
                        </div>
                        <div className="text-center">
                            <span className="d-block font-m font-dark-grey">Low</span>
                            <span className="font-m">59401.00</span>
                        </div>
                        <div className="text-center">
                            <span className="d-block font-m font-dark-grey">Close</span>
                            <span className="font-m">59401.00</span>
                        </div>
                    </div>
                    <div>
                        <Table className="buysell-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>BUY PRICE</th>
                                    <th>SELL PRICE</th>
                                    <th>QTY</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red">249.50</div></td>
                                    <td>1747</td>
                                </tr>
                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red width-70">249.50</div></td>
                                    <td>4270</td>
                                </tr>
                                <tr>
                                    <td>0.123</td>
                                    <td><div className="graph width-50">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>1123</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-80">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-100">249.50</div></td>
                                    <td><div className="graph red width-100">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>3524354</td>
                                    <td className="text-center" colSpan={2}>TOTAL QUANTITY</td>
                                    <td>1563503</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="chart-button">
                        <Button className="chart-btn">
                            {" "}
                            <img className="me-2" src="images/chart.svg" alt="chart" />
                            Chart
                        </Button>
                    </div>
                    <ul className="popup-lists">
                        <li>
                            <span className="font-m font-dark-grey">Volume</span>
                            <span className="font-m text-black">72,031</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Avg. traded price</span>
                            <span className="font-m font-blue">26.08</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">
                                Last traded quantity
                            </span>
                            <span className="font-m font-red">20</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded at</span>
                            <span className="font-m text-black">2023-05-17 15:29:44</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last circuit</span>
                            <span className="font-m text-black">21.15</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Upper circuit</span>
                            <span className="font-m font-red">31.65</span>
                        </li>
                    </ul>

                </div>
            </Offcanvas.Body>

        </Offcanvas>
    </>
}