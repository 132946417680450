// Import your components
import Splash from '../screens/public/Splash.jsx';
import Onboarding from '../screens/public/Onboarding.jsx';
import Login from '../screens/public/Login.jsx';
import Signup from '../screens/public/signup/Signup.jsx';
import ForgotPassword from '../screens/public/forgotPassword/ForgotPassword.jsx';
import Verification from '../screens/public/signup/Verification.jsx';
import VerificationForgot from '../screens/public/forgotPassword/Verification.jsx';
import OtpVerification from '../screens/private/OtpVerification';
import ResetPassword from '../screens/public/forgotPassword/ResetPassword.jsx';
import Market from '../screens/private/market/Market.jsx';
import MarketLooser from '../screens/private/MarketLooser.jsx';
import Watchlist from '../screens/private/watchlist/Watchlist.jsx';
import WatchlistError from '../screens/private/WatchlistError.jsx';
import WatchlistSuccessful from '../screens/private/WatchlistSuccessful.jsx';
import PendingOrders from '../screens/private/orderPage/PendingOrders.jsx';
import ExecutedOrders from '../screens/private/ExecutedOrders.jsx';
import Trades from '../screens/private/Trades.jsx';
import Portfolio from '../screens/private/portfolio/Portfolio.jsx';
import PortfolioB from '../screens/private/PortfolioB.jsx';
import Account from '../screens/private/account/Account.jsx';
import AccountProfile from '../screens/private/profile/AccountProfile.jsx';
import AccountChangePassword from '../screens/private/changePassword/AccountChangePassword.jsx';
import AccountDeposite from '../screens/private/transaction/AccountDeposite.jsx';
import AccountTransaction from '../screens/private/transaction/AccountTransaction.jsx';
import AccountWithdraw from '../screens/private/transaction/AccountWithdraw.jsx';
import AccountRejectionLogs from '../screens/private/rejectionLogs/AccountRejectionLogs.jsx';
import AccountLedgerMaster from '../screens/private/ledgerMaster/AccountLedgerMaster.jsx';
import OrdersExecuted from '../screens/private/OrdersExecuted.jsx';
import BillHistory from '../screens/private/bill/BillHistory.jsx';
import PersonalDetailEdit from '../screens/private/PersonalDetailEdit.jsx';
import AccountTheme from '../screens/private/AccountTheme.jsx';
import WatchlistBuy from '../screens/private/WatchlistBuy.jsx';
import WatchlistSell from '../screens/private/WatchlistSell.jsx';
import ExecutedDropdown from '../screens/private/ExecutedDropdown.jsx';
import SearchBar from '../screens/private/watchlist/SearchBar.jsx';
import SearchBarHover from '../screens/private/SearchBarHover.jsx';
import Notification from '../screens/private/Notification.jsx';
import ReferAndEarn from '../screens/private/ReferAndEarn.jsx';
// import WatchlistBuyTwo from '../screens/private/WatchlistBuyTwo.jsx';
import PushOrder from '../screens/private/pushOrder/PushOrder.jsx';
import WatchlistBuySell from '../screens/private/WatchlistBuySell.jsx';
import LeaderBoard from '../screens/private/LeaderBoard.jsx';
import TradeyaarReferrals from '../screens/private/TradeyaarReferrals.jsx';
import PortfolioPopup from '../screens/private/PortfolioPopup.jsx';
import PortfolioExit from '../screens/private/PortfolioExit.jsx';

// Define an array of route objects
export const routes = [
    { path: "/market", component: Market },
    { path: "/market-looser", component: MarketLooser },
    { path: "/watchlist", component: Watchlist },
    { path: "/watchlist-error", component: WatchlistError },
    { path: "/watchlist-successful", component: WatchlistSuccessful },
    { path: "/order", component: PendingOrders },
    { path: "/executed-orders", component: ExecutedOrders },
    { path: "/trades", component: Trades },
    { path: "/portfolio", component: Portfolio },
    { path: "/portfolio-b", component: PortfolioB },
    { path: "/account", component: Account },
    { path: "/account-profile", component: AccountProfile },
    { path: "/account-change-password", component: AccountChangePassword },
    { path: "/account-transaction", component: AccountTransaction },
    { path: "/account-deposite", component: AccountDeposite },
    { path: "/account-withdraw", component: AccountWithdraw },
    { path: "/account-rejection-logs", component: AccountRejectionLogs },
    { path: "/account-ledger-master", component: AccountLedgerMaster },
    { path: "/orders-executed", component: OrdersExecuted },
    { path: "/bill-history", component: BillHistory },
    { path: "/personal-detail-edit", component: PersonalDetailEdit },
    { path: "/account-theme", component: AccountTheme },
    { path: "/watchlist-buy", component: WatchlistBuy },
    { path: "/watchlist-sell", component: WatchlistSell },
    { path: "/executed-dropdown", component: ExecutedDropdown },
    { path: "/search-bar", component: SearchBar },
    { path: "/search-bar-hover", component: SearchBarHover },
    { path: "/notification", component: Notification },
    { path: "/refer-and-earn", component: ReferAndEarn },
    { path: "/pushOrder", component: PushOrder },
    // { path: "/watchlist-sell-two", component: WatchlistSellTwo },
    { path: "/watchlist-buy-sell", component: WatchlistBuySell },
    { path: "/leaderboard", component: LeaderBoard },
    { path: "/tradeyaar-referrals", component: TradeyaarReferrals },
    { path: "/portfolio-popup", component: PortfolioPopup },
    { path: "/portfolio-exit", component: PortfolioExit },
    { path: "/", component: Market },
];

export const publicRoutes = [
    // { path: "/", component: Splash },
    { path: "/login", component: Login },
    { path: "/signup", component: Signup },
    { path: "/verification", component: Verification },
    { path: "/verification-forgot", component: VerificationForgot },
    // { path: "/otp-verification", component: OtpVerification },
    { path: "/forgot-password", component: ForgotPassword },
    { path: "/reset-password", component: ResetPassword },
    { path: "/", component: Onboarding }
];