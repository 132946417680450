import { FETCH_INDEXES } from "./actionTypes";
let initialState = {
  "index1": {},
  "index2": {}
};
const indexBox = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INDEXES:
      return action.payload;
    default:
      return state;
  }
};
export default indexBox;
