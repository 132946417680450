import React from "react";
import { Tab, Nav, Form, Button } from "react-bootstrap";

export default function PersonalDetailEdit() {
  return (
    <div className="screen mb-3">
      <div className="main-content bg-light">
        <div className="d-flex align-items-center justify-content-between mb-30">
          <div className="col-6">
            <span class="d-flex align-items-center fw-semibold gap-2">
              <img src="images/left-arrow.svg" alt="left-arrow"></img>Personal Detail Edit
            </span>
          </div>
          <div className="col-6">
            <Tab.Container id="tabs-example" defaultActiveKey="forex">
              <Nav
                className="d-flex justify-content-end orders-tab"
                variant="tabs"
              >
                <Nav.Item>
                  <Nav.Link eventKey="inr">INR</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="forex">Forex</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="inr"></Tab.Pane>
                <Tab.Pane eventKey="forex"></Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <Form>
          <Form.Group className="mb-30" controlId="OnlineDeposite">
            <Form.Label className="custom-label fw-seibold mb-2">
              Email
            </Form.Label>
            <Form.Control
              className="custom-form-control"
              size="lg"
              type="text"
              placeholder="XXXXXXXXXX"
            />
          </Form.Group>

          <div className="mt-4">
            <Button className="signin-btn w-100">Save</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

