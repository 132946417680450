import { COONECT_PRICE_SOCKET, CONECT_NOTIFY_SOCKET } from "./actionTypes";
import io from 'socket.io-client';

let initialState = {
  priceSocket: io('https://ws2.bullex.in'),
  notifySocket: io('https://ws1.bullex.in'),
  // owner: [],
};
const socket = (state = initialState, action) => {
  switch (action.type) {
    case COONECT_PRICE_SOCKET:
      if (state["priceSocket"]) {
        return state
      }
      return {
        ...state,
        priceSocket: action.payload
      };
    case CONECT_NOTIFY_SOCKET:
      if (state["notifySocket"]) {
        return state
      }
      return {
        ...state,
        notifySocket: action.payload
      };
    default:
      return state;
  }
};
export default socket;
