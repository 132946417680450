import { SET_SIGNUP_DATA } from "./actionTypes";
let initialState = {
  "signup": {}
};

const signup = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNUP_DATA:
      return {
        "signup": action.payload
      };
    default:
      return state;
  }
};
export default signup;