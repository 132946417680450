import login from "./login/reducer";
// import order from "./Order/reducer";
import market from "./market/reducer";
import watchlist from "./watchlist/reducer";
import portfolio from "./portfolio/reducer";
import profile from "./profile/reducer";
import ledgerMaster from "./ledgerMaster/reducer";
import socket from "./socket/reducer";
import bottomSheetWatchlist from "./bottomSheetWatchlist/reducer";
import pushOrder from "./pushOrder/reducer";
import currencyRate from "./currencyRate/reducer";
import order from "./order/reducer";
import transaction from "./transaction/reducer";
import rejectionLogs from "./rejectionLog/reducer";
import bill from "./bill/reducer";
import signup from "./signup/reducer";
import indexBox from "./indexBox/reducer";
import bottomSheetPortfolio from "./bottomSheetPortfolio/reducer";
import forgot from "./forgot/reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  login,
  market,
  watchlist,
  portfolio,
  profile,
  ledgerMaster,
  socket,
  bottomSheetWatchlist,
  pushOrder,
  currencyRate,
  order,
  transaction,
  rejectionLogs,
  bill,
  signup,
  indexBox,
  bottomSheetPortfolio,
  forgot
});
export default rootReducer;
