import { FETCH_PORTFOLIO, CHANGE_PORTFOLIO_CURRENCY } from "./actionTypes";
import { FETCH_PORTFOLIO_URL } from "../../helper/url_helper";
import api from "../../helper/api_helper";
import { toast } from "react-toastify";

export const fetchPortfolio = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_PORTFOLIO_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_PORTFOLIO, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const changeCurrency = (currency) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_PORTFOLIO_CURRENCY, payload: currency });
  };
};
