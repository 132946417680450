import { FETCH_GAINERS, FETCH_LOOSERS } from "./actionTypes";
import { FETCH_GAINERS_URL, FETCH_LOOSERS_URL } from "../../helper/url_helper";
import api from "../../helper/api_helper";
import { toast } from "react-toastify";

export const fetchGainers = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_GAINERS_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_GAINERS, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const fetchLoosers = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_LOOSERS_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_LOOSERS, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};


// export const fetchOwner = (data) => {
//   return async (dispatch) => {
//     try {
//       api.defaults.headers.common["Authorization"] = `Bearer ${data.jwttoken}`;
//       let res = await api.get(FETCH_OWNER_URL);
//       if (res.data.status === "SUCCESS") {
//         dispatch({ type: FETCH_OWNER, payload: res.data.payload });
//       } else {
//         toast.error(res.data.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
// }