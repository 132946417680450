import React, { useEffect } from "react";
import { Tab, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../../../store/profile/action";
import { useHistory } from 'react-router-dom';


export default function AccountProfile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const profileState = useSelector((state) => state.profile);
    useEffect(() => {
        dispatch(fetchProfile(loginState.jwttoken))
    }, [])

    if (profileState.username == undefined) {
        return <></>
    }

    return (
        <div className="screen mb-3">
            <div className="main-content bg-light">
                <span className="d-flex align-items-center" onClick={() => { history.goBack() }}><img className="me-2" src="images/left-arrow.svg" alt="left-arrow" />Profile</span>
                <div className="text-center ei-logo">
                    <span className="font-l">{profileState.firstname[0]}{profileState.lastname[0]}</span>
                </div>
                <div className="account-title bg-white mb-3 text-center">
                    <h4 className="font-m fw-semibold mb-0 mt-4">{profileState.firstname} {profileState.lastname}</h4>
                    <span className="font-s">I.D: {profileState.id}</span>
                </div>
                <div className="d-flex align-items-end bg-white radius-5 p-3 mb-2">
                    <img src="images/userid.svg" alt="account-profile-icon" className="me-2" />
                    <div>
                        <span className="d-block">User ID</span>
                        <span className="d-block fw-semibold lh-1">{profileState.username}</span>
                    </div>

                </div>
                <div className="bg-white radius-5 p-3 mb-3">
                    <span className="fs-m mb-3 d-block">Personal Details</span>
                    <div className="d-flex align-items-end border-bottom-1 mb-13 pb-13">
                        <img src="images/phone.svg" alt="phone" className="me-2" />
                        <div>
                            <span className="d-block font-dark-grey font-m mb-1">Mobile Number</span>
                            <span className="d-block fw-semibold lh-1">{profileState.contact ? profileState.contact : "-"}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-end border-bottom-1 mb-13 pb-13 position-relative">
                        <img src="images/email.svg" alt="email" className="me-2" />
                        <div>
                            <span className="d-block font-dark-grey font-m mb-1">Email</span>
                            <span className="d-block fw-semibold lh-1">{profileState.email ? profileState.email : "-"}</span>
                        </div>
                        {/* <div className="profile-edit">
                            <img src="images/edit.svg" alt="edit" className="me-2" />
                        </div> */}

                    </div>
                    <div className="d-flex align-items-end">
                        <img src="images/location.svg" alt="location" className="me-2" />
                        <div>
                            <span className="d-block font-dark-grey font-m mb-1">Location</span>
                            <span className="d-block fw-semibold lh-1">{profileState.address ? profileState.address : "-"}</span>
                        </div>
                    </div>

                </div>
                <div className="profile-banner d-flex justify-content-between align-items-center mb-3">

                    <div>
                        <span className="d-block text-white font-20 mb-10">Refer Rewards</span>
                        <p className="text-white font-m">Earn $8.50 worth of BTC on every successful referral.</p>
                        <span className="d-block yellow-light lh-1 d-flex align-items-center">Refer a Friend <img src="images/right-yellow-arrow.svg" alt="right-yellow-arrow" className="ms-1" /></span>
                    </div>
                    <img src="images/profile-banner.svg" alt="profile-banner" />
                </div>

                <div className="bg-white radius-5 p-3 mb-3">
                    <span className="fs-m mb-30 d-block">Segment Subscriptions</span>
                    <span className="fs-m mb-3 d-block">Equity Trading</span>
                    <ul className="subscriptions-lists">
                        <li>
                            <span className="font-dark-grey">NSE</span>
                            {
                                (profileState.client_account.is_equity_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                        <li>
                            <span className="font-dark-grey">NSE Options</span>
                            {
                                (profileState.client_account.is_equity_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                    </ul>
                    <span className="fs-m mb-3 d-block">Commodity Trader</span>
                    <ul className="subscriptions-lists">
                        <li>
                            <span className="font-dark-grey">MCX Future</span>
                            {
                                (profileState.client_account.is_mcx_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                        <li>
                            <span className="font-dark-grey">MCX Options</span>
                            {
                                (profileState.client_account.is_mcx_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                    </ul>
                    <ul className="subscriptions-lists">
                        <li>
                            <span className="font-dark-grey">Forex</span>
                            {
                                (profileState.client_account.is_forex_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                        <li>
                            <span className="font-dark-grey">Crypto</span>
                            {
                                (profileState.client_account.is_equity_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>

                        <li>
                            <span className="font-dark-grey">US</span>
                            {
                                (profileState.client_account.is_us_trading_active === "yes") ?
                                    <img src="images/right-mark-round.svg" alt="right-mark-round" /> :
                                    <img src="images/close-round.svg" alt="close-round" />
                            }

                        </li>
                    </ul>
                </div>


            </div>
        </div >
    );
};


