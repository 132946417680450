import React from "react";
import { useState } from 'react';
import { Tab, Nav, Modal, Button, Form } from 'react-bootstrap';
export default function AccountTheme() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="screen mb-3">
            <div className="main-content bg-light">
                <span className="font-m fw-semibold">Account Theme</span>
                <div className="text-center ei-logo">
                    <span className="font-l">EI</span>
                </div>
                <div className="account-title bg-white mb-3 text-center">
                    <h4 className="font-m fw-semibold mb-0 mt-4">Edwin Izantim</h4>
                    <span className="font-s">I.D: 214AV02</span>
                </div>
                <ul className="account-lists">
                    <li>
                        <span className="d-flex align-items-center"><img src="images/account-profile.svg" alt="account-profile-icon" className="me-2" />Profile</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/transection-history.svg" alt="transection-history-icon" className="me-2" />Transaction History</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/password.svg" alt="Password-icon" className="me-2" />Change Password</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/rejection-logs.svg" alt="rejection-logs-icon" className="me-2" />Rejection logs</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/ledger-mast.svg" alt="ledger-mast-icon" className="me-2" />Ledger Master</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/bill-history.svg" alt="bill-history-icon" className="me-2" />Bill History</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/gift.svg" alt="gift-icon" className="me-2" />7 Level Refreal Program</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/leader-board.svg" alt="leader-board-icon" className="me-2" />Leader board</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/about-us.svg" alt="about-us-icon" className="me-2" />About Us</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/terms-and-condition.svg" alt="terms-and-condition-icon" className="me-2" />Terms and Conditions</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/help.svg" alt="help-icon" className="me-2" />Help</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/theme.svg" alt="theme-icon" className="me-2" /><Button className="bg-transparent text-black p-0 border-0" onClick={handleShow}>Theme</Button></span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                </ul>
                <div class="mt-3"><button type="button" class="signin-btn w-100 btn btn-primary"><span className="d-flex align-items-center justify-content-center"><img src="images/log-out.svg" alt="logout-icon" className="me-1"></img>Logout</span></button></div>
                <Modal centered className="theme-popup" show={show} onHide={handleClose}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className="p-4">
                        <div class="font-ml fw-semibold mb-16">Choose theme</div>
                        <Form>
                            <Form.Check
                                type="radio"
                                className="pb-20"
                                label="System default"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios1"

                            />
                            <Form.Check
                                type="radio"
                                className="pb-20"
                                label="Light"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios2"
                            />
                            <Form.Check
                                type="radio"
                                label="Dark"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios3"
                            />
                        </Form>

                    </Modal.Body>
                    <Modal.Footer className="border-0 pb-4 pt-0">
                        <Button className="cancle-btns me-4" onClick={handleClose}>
                            Cancle
                        </Button>
                        <Button className="ok-btns" onClick={handleClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="footer">
                <Tab.Container id="tabs-example" defaultActiveKey="account">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};

