import { UPDATE_INSTRUMENT_PUSH_ORDER } from "./actionTypes";
let initialState = {
  "instrument": {},
  "defaultQty": 1,
  "type": "buy"
};
const pushOrder = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INSTRUMENT_PUSH_ORDER:
      return {
        ...state,
        "instrument": action.payload.instrument,
        "defaultQty": action.payload.defaultQty,
        "type": action.payload.type
      };
    default:
      return state;
  }
};
export default pushOrder;
