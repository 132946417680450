import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SEARCH_INSTRUMENT_URL, ADD_WATCHLIST_URL, REMOVE_WATCHLIST_URL } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function SearchBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ instruments: [], watchlist_tokens: [] });
  useEffect(() => { submitHandler() }, [search]);
  const labelClass = {
    "NFO": "label-red",
    "MCX": "label-blue",
    "FX": "label-green",
    "CRYPTO": "lablel-blue",
    "US": "label-red",
    "CDS": "label-blue"
  }
  async function submitHandler() {
    try {
      if (!search) {
        setData({ ...data, instruments: [] });
        return
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(SEARCH_INSTRUMENT_URL, { "query": search, "exchange": "ALL" });
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function addWatchlist(token) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(ADD_WATCHLIST_URL, { "token": token });
      if (res.data.status === "SUCCESS") {
        submitHandler();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function removeWatchlist(token) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(REMOVE_WATCHLIST_URL, { "token": token });
      if (res.data.status === "SUCCESS") {
        submitHandler();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="screen">
      <div className="main-content bg-light">
        <span class="d-flex align-items-center fw-semibold gap-3 mb-30" onClick={() => { history.goBack() }}>
          <img src="images/left-arrow.svg" alt="left-arrow" />
          Add Script
        </span>

        <Form className="search-items mb-2">
          <Form.Group>
            <div className="search-icon">
              <img class="right-icon" src="images/search.svg" />
            </div>
            <Form.Control
              className="search-form-control"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => { setSearch(e.target.value) }}
            />
            <div className="clear-label">
              <span className="font-s" onClick={() => setSearch("")}>Clear</span>
            </div>
          </Form.Group>
        </Form>

        {data.instruments.map((instrument) => {
          return <>
            <Card className="border-0 p-0 mb-2 w-100" key={instrument.token}>
              <Card.Body className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span className={`font-m ${labelClass[instrument.exchange]} p-1`}>{instrument.exchange}</span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <span className="font-m mb-0 lh-1">{instrument.trading_symbol}</span>
                      {/* <span className="font-m font-dark-grey ms-1">14 SEP 23 </span> */}
                    </div>
                  </div>
                  {data.watchlist_tokens.includes(instrument.token) ?
                    <span className="label label-fill-blue py-0" onClick={() => { removeWatchlist(instrument.token) }}>+</span> :
                    <span className="label label-border-blue py-0" onClick={() => addWatchlist(instrument.token)}>+</span>
                  }
                  {/* <img src="images/plus-icon.svg" alt="plus-icon" /> */}
                </div>
              </Card.Body>
            </Card>
          </>
        })}

      </div>
    </div>
  );
};

