import React, { useEffect, useState } from "react";
import { Tab, Nav, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../../store/ledgerMaster/action";
import { useHistory } from 'react-router-dom';

export default function AccountLedgerMaster() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const ledgerMasterState = useSelector((state) => state.ledgerMaster);
  const [currency, setCurrency] = useState("INR");
  useEffect(() => {
    dispatch(fetchData(loginState.jwttoken))
  }, [])


  function changeCurrencyHandler(currency) {
    setCurrency(currency);
  }

  return (
    <div className="screen">
      <div className="main-content bg-light">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="col-6">
            <span class="d-flex align-items-center fw-semibold gap-2" onClick={() => { history.goBack() }}>
              <img src="images/left-arrow.svg" alt="left-arrow"></img>Ledger Master
            </span>
          </div>
          <div className="col-6">
            <Tab.Container id="tabs-example">
              <Nav
                className="d-flex justify-content-end orders-tab"
                variant="tabs"
              >
                <Nav className="d-flex justify-content-end orders-tab" variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="inr" className={(currency === "INR") ? "active" : ""} onClick={() => { changeCurrencyHandler("INR") }}>INR</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="forex" className={(currency === "USD") ? "active" : ""} onClick={() => { changeCurrencyHandler("USD") }}>Forex</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="inr"></Tab.Pane>
                <Tab.Pane eventKey="forex"></Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <div>
          <div className="account-ledger-master-pannel">
            {ledgerMasterState[currency].map((row, index) => {
              return <>
                <Card className="border-0 p-0 mb-2" key={index}>
                  <Card.Body className="px-3 py-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <span className="d-block fw-medium mb-2">{row.description}</span>
                        <span className={`${(row.amount > 0) ? "font-green" : "font-red"} font-ml`}>{parseFloat(row.amount).toFixed(2)}</span>
                      </div>
                      <div className="text-end">
                        <span className="d-block font-s font-dark-grey mb-2">{row.added_on}</span>
                        <span className={`${(row.amount > 0) ? "font-green" : "font-red"} font-ml`}>{parseFloat(row.closing).toFixed(2)}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </>
            })}

          </div>
        </div>
      </div>
    </div>
  );
};


