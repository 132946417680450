import { FETCH_LEDGER_MASTER } from "./actionTypes";
let initialState = {
  "INR": [],
  "USD": []
};
const ledgerMaster = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEDGER_MASTER:
      return action.payload;
    default:
      return state;
  }
};
export default ledgerMaster;
