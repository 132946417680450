import { CHANGE_STATUS_SHEET_PORTFOLIO, UPDATE_INSTRUMENT_SHEET_PORTFOLIO } from "./actionTypes";

export const changeStatusSheetPortfolio = (status) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_STATUS_SHEET_PORTFOLIO, payload: status });
  };
};

export const updateDataSheetPortfolio = (instrument, position) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_INSTRUMENT_SHEET_PORTFOLIO, payload: { instrument: instrument, position: position } });
  };
};
