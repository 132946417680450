import { FETCH_LEDGER_MASTER } from "./actionTypes";
import { FETCH_LEDGER_MASTER_URL } from "../../helper/url_helper";
import api from "../../helper/api_helper";
import { toast } from "react-toastify";

export const fetchData = (jwttoken) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      let res = await api.get(FETCH_LEDGER_MASTER_URL);
      if (res.data.status === "SUCCESS") {
        // toast.success(res.data.message);
        dispatch({ type: FETCH_LEDGER_MASTER, payload: res.data.payload });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

