import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";

export default function TradeyaarReferrals() {

  const handleShare = async () => {
    try {
      // Check if the Web Share API is supported by the browser
      if (navigator.share) {
        await navigator.share({
          title: 'Title of shared content',
          text: 'Description of shared content',
          url: 'https://example.com', // URL to be shared
        });
        toast.success('Shared successfully');
      } else {
        throw new Error('Web Share API not supported');
      }
    } catch (error) {
      toast.error('Error sharing:', error);
    }
  };

  return (
    <>
      <div className="screen mb-3">
        <div className="main-content p-0 bg-white ">
          <span class="d-flex align-items-center fw-semibold gap-3 pb-4 p-3 pb-0 bg-light ">
            <img src="images/left-arrow.svg" alt="left-arrow"></img>Referal and
            earn
          </span>
          <Card className="border-0 p-0 mb-2 bg-white radius-top-20">
            <Card.Body className="pt-3">
              <span className="d-block fw-semibold font-lg mb-1">
                Tradeyaar Referrals
              </span>
              <span className="d-block font-s">
                Both you and your friend earn rewards!
              </span>
              <img
                src="images/tradeyaar-referrals-banner.svg"
                alt="tradeyaar-referrals-banner"
              />
              <div className="mt-3">
                <span className="font-blue font-s fw-semibold">
                  <img
                    src="images/information-blue.svg"
                    alt="information-blue-icon"
                    className="me-2"
                  />
                  How To Refer?
                </span>
              </div>
              <div className="refer-roadmap">
                <ul>
                  <li>
                    <span className="bg-white rounded-circle number-span">
                      1
                    </span>
                    <span className="ms-3 font-m text-black">
                      Share your referral link with friends.
                    </span>
                    <div className="step-line-wrapper">
                      <div className="step-line"></div>
                    </div>
                  </li>
                  <li>
                    <span className="bg-white rounded-circle number-span">
                      2
                    </span>
                    <span className="ms-3 font-m text-black">
                      Your friends sign up for Tradeyaar.
                    </span>
                    <div className="step-line-wrapper">
                      <div className="step-line"></div>
                    </div>
                  </li>
                  <li>
                    <span className="bg-white rounded-circle number-span">
                      3
                    </span>
                    <span className="ms-3 font-m text-black">
                      Both get bonus rewards of 10.00 USDT.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-5 d-flex align-items-center justify-content-center gap-4">
                <span className="d-flex flex-column gap-2 align-items-center font-s">
                  <span>
                    <img
                      src="images/copy-round-icon.svg"
                      alt="copy-round-icon"
                    />
                  </span>
                  Copy
                </span>
                <span className="d-flex flex-column gap-2 align-items-center font-s" onClick={handleShare}>
                  <span>
                    <img src="images/share-icon.svg" alt="share-icon" />
                  </span>
                  Share
                </span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};


