import React from 'react';
import { TopGainer, TopLooser } from '../../components/market/smallComponents.jsx';
import { Footer } from '../../components/common/common.jsx';

import { Card, Tab, Tabs, Nav } from 'react-bootstrap';

export default function MarketLooser() {
    return <>
        <div className="screen">
            <div className="main-content bg-light">
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div>
                                <span className="icon-red me-2">SS</span>
                                <span>Hi,Samar Khurana</span>
                            </div>
                            <ul className="icons-list">
                                <li><img src="images/search-icon.svg" alt="search-icon" /></li>
                                <li><img src="images/notification.svg" alt="notification-bell" /><span className="notification-count">12</span></li>
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex gap-3">
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/bitcoin.svg" alt="bitcoin" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/BTC</span>
                                <small className="font-red font-s">-132,5%<img className="ms-2" src="images/red-arrow.svg" alt="red-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/usdt-icon.svg" alt="usdt-icon" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/USDT</span>
                                <small className="font-green font-s">-132,5%<img className="ms-2" src="images/green-arrow.svg" alt="green-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Tab.Container id="tabs-example" defaultActiveKey="crypto">
                        <Nav className="d-flex justify-content-between custom-tab" variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="crypto">Crypto</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="forex">Forex</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fno">FNO</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="mcx">MCX</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="us">US</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="crypto">
                                <div>
                                    <Tabs
                                        defaultActiveKey="top-loosers"
                                        id="uncontrolled-tab-example"
                                        className="d-flex gap-2 button-tabs"
                                    >
                                        <Tab eventKey="top-gainers" title="Top Gainers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <div className="market-pannel-status">
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                    <TopGainer />
                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="top-loosers" title="Top Loosers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <div className="market-pannel-status">
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                    <TopLooser />
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="forex">
                                <div>
                                    <Tabs
                                        defaultActiveKey="top-gainers"
                                        id="uncontrolled-tab-example"
                                        className="d-flex gap-2 button-tabs"
                                    >
                                        <Tab className="slide-in" transition={false} eventKey="top-gainers" title="Top Gainers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                            </div>
                                        </Tab>
                                        <Tab className="slide-in" transition={false} eventKey="top-loosers" title="Top Loosers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fno">
                                <div>
                                    <Tabs
                                        defaultActiveKey="top-gainers"
                                        id="uncontrolled-tab-example"
                                        className="d-flex gap-2 button-tabs"
                                    >
                                        <Tab className="slide-in" transition={false} eventKey="top-gainers" title="Top Gainers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                            </div>
                                        </Tab>
                                        <Tab className="slide-in" transition={false} eventKey="top-loosers" title="Top Loosers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mcx">
                                <div>
                                    <Tabs
                                        defaultActiveKey="top-gainers"
                                        id="uncontrolled-tab-example"
                                        className="d-flex gap-2 button-tabs"
                                    >
                                        <Tab className="slide-in" transition={false} eventKey="top-gainers" title="Top Gainers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                            </div>
                                        </Tab>
                                        <Tab className="slide-in" transition={false} eventKey="top-loosers" title="Top Loosers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="us">
                                <div>
                                    <Tabs
                                        defaultActiveKey="top-gainers"
                                        id="uncontrolled-tab-example"
                                        className="d-flex gap-2 button-tabs"
                                    >
                                        <Tab className="slide-in" transition={false} eventKey="top-gainers" title="Top Gainers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                                <TopGainer />
                                            </div>
                                        </Tab>
                                        <Tab className="slide-in" transition={false} eventKey="top-loosers" title="Top Loosers">
                                            <div>
                                                <Card className="border-0 p-0 mb-3 bg-transparent">
                                                    <Card.Body className="px-3 py-0">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <div><span>NAME</span></div>
                                                            <div className="text-center"><span>VALUE</span></div>
                                                            <div><span>CHG (%)</span></div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                                <TopLooser />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            <Footer />
        </div>

    </>
}