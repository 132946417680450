import { useState } from 'react';
import { Card, Button, Offcanvas, Table, Row, Col, Toast, Alert } from 'react-bootstrap';
import { convertDateFormat } from '../../helper/general_helper';
// import BottomSheet from '../common/BottomSheet';

export function IndexBox({ className }) {
    return <>
        <div className={"card bg-white " + className}>
            <div className='d-flex justify-content-center align-items-center px-5px'>
                <div className='mr-15px'><span className='icon-md icon-red'>SS</span></div>
                <div><span className='font-m'>Crypto/Btc</span><br /><span className='font-s font-red'>+232</span></div>
            </div>
        </div>
    </>;
}

export function WatchlistBox({ instrument, sheetChangingHandler }) {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    return <>
        {/* <div className="card bg-white">
            <div className="d-flex align-items-center">
                <div className="col-6"><span className="font-m">NATURALGAS</span><br /><span className="font-s font-dark-grey">2024-03-28</span><br /><span className="font-s font-blue">+253</span> <span className="font-s font-dark-grey">LTP:596</span></div>
                <div className="col-3 text-align-center"><span className="font-m font-red">275.45</span><br /><span className="font-s font-dark-grey">L:273</span></div>
                <div className="col-3 text-align-right"><span className="font-m font-green">275.05</span><br /><span className="font-s font-dark-grey">H:273</span></div>
            </div>
        </div> */}
        {/* <Card className="border-0 p-0 mb-2" onClick={handleShow}>
            <Card.Body className="py-2 px-3 bg-active">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold d-block">Ethereum</span>
                        <span className="font-s font-dark-grey">05 Jun 23</span>
                        <div>
                            <span className="font-s font-blue me-2">+253.00</span>
                            <span className="font-s font-dark-grey">LTP:59605.00</span>
                        </div>
                    </div>
                    <div className="text-end">
                        <span className="font-m font-green fw-semibold d-block">59590.00</span>
                        <span className="font-s font-dark-grey">L: 59271.00</span>
                    </div>
                    <div className="text-end">
                        <span className="font-m font-red fw-semibold d-block">59590.00</span>
                        <span className="font-s font-dark-grey">H: 59631.00</span>
                    </div>
                </div>
            </Card.Body>
        </Card> */}
        <Card className="border-0 p-0 mb-2" onClick={() => { sheetChangingHandler(instrument) }}>
            <Card.Body className="py-2 px-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold d-block">{instrument.symbol}</span>
                        <span className="font-s font-dark-grey">{((instrument.expiry === "-") ? "-" : convertDateFormat(instrument.expiry))}{["OPTIDX", "OPTFUT", "OPTSTK"].includes(instrument.segment) && (` ${instrument.strike_price} ${instrument.option_type}`)}</span>
                        <div>
                            <span className={`font-s me-2 ${(instrument.percentage_change > 0) ? "font-green" : "font-red"}`}>{instrument.percentage_change}%</span>
                            <span className="font-s font-dark-grey">LTP:{instrument.ltp}</span>
                        </div>
                    </div>
                    <div className="text-end">
                        <span className={`font-m ${(instrument.bid_price > instrument.pre_bid_price) ? "font-green" : "font-red"} fw-semibold d-block`}>{instrument.bid_price}</span>
                        <span className="font-s font-dark-grey">L: {instrument.low_price}</span>
                    </div>
                    <div className="text-end">
                        <span className={`font-m ${(instrument.ask_price > instrument.pre_ask_price) ? "font-green" : "font-red"} fw-semibold d-block`}>{instrument.ask_price}</span>
                        <span className="font-s font-dark-grey">H: {instrument.high_price}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>

    </>
}


export function BottomSheetWatchlist() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>

        <Offcanvas className="watchlistbuysell-popup" placement="bottom" show={false} onHide={handleClose}>
            {/* <Offcanvas.Header closeButton>
            </Offcanvas.Header> */}
            <Offcanvas.Body className="p-0 bg-light border-radius-top-20">
                <div className="px-3 py-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                            <span className="d-block font-m fw-semibold d-flex align-items-center">NATURALGAS<span className="label-white font-s text-black ms-2">CDS</span></span>
                            <span className="font-s font-red">Lots size : 1435.00</span>
                        </div>
                        <div className="text-end">
                            <span className="d-block font-ml font-green fw-semibold">1730.00</span>
                            <span className="font-s font-dark-grey">+3.90 (+1.59%)</span>
                        </div>
                    </div>
                    <div className="trading-balance-modal bg-white p-3 d-flex justify-content-between">
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Open</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">High</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Low</span><span className="font-m">59401.00</span></div>
                        <div className="text-center"><span className="d-block font-m font-dark-grey">Close</span><span className="font-m">59401.00</span></div>
                    </div>
                    <div>
                        <Table className="buysell-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>BUY PRICE</th>
                                    <th>SELL PRICE</th>
                                    <th>QTY</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red">249.50</div></td>
                                    <td>1747</td>
                                </tr>
                                <tr>
                                    <td>0.1223</td>
                                    <td><div className="graph">249.50</div></td>
                                    <td><div className="graph red width-70">249.50</div></td>
                                    <td>4270</td>
                                </tr>
                                <tr>
                                    <td>0.123</td>
                                    <td><div className="graph width-50">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>1123</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-80">249.50</div></td>
                                    <td><div className="graph red width-80">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>0.103</td>
                                    <td><div className="graph width-100">249.50</div></td>
                                    <td><div className="graph red width-100">249.50</div></td>
                                    <td>15696</td>
                                </tr>
                                <tr>
                                    <td>3524354</td>
                                    <td className="text-center" colSpan={2}>TOTAL QUANTITY</td>
                                    <td>1563503</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="chart-button">
                        <Button className="chart-btn"> <img className="me-2" src="images/chart.svg" alt="chart" />Chart</Button>
                    </div>
                    <ul className="popup-lists">
                        <li>
                            <span className="font-m font-dark-grey">Volume</span>
                            <span className="font-m text-black">72,031</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Avg. traded price</span>
                            <span className="font-m text-black">26.08</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded quantity</span>
                            <span className="font-m text-black">20</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last traded at</span>
                            <span className="font-m text-black">2023-05-17  15:29:44</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Last circuit</span>
                            <span className="font-m text-black">21.15</span>
                        </li>
                        <li>
                            <span className="font-m font-dark-grey">Upper circuit</span>
                            <span className="font-m text-black">31.65</span>
                        </li>
                    </ul>
                    <div className="portfolio-modal-button">
                        <Button className="buy-btn">
                            BUY
                            <span className="d-block">@256.00</span>
                        </Button>
                        <Button className="sell-btn">
                            SELL
                            <span className="d-block">@256.00</span>
                        </Button>
                    </div>
                </div>


            </Offcanvas.Body>

        </Offcanvas>
    </>
}

export function ToastMessage() {
    const [show, setShow] = useState(false);

    return (
        <div className="toast-message">
            <Alert className="bg-green">
                <Alert.Heading>Toast Message</Alert.Heading>
            </Alert>
        </div>
    );
}

export function EmptyPageImage() {
    return (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <img src="images/orderexecuted-image.svg" alt="orderexecuted-image" />
            <div className="font-l text-center font-dark-grey fw-normal mt-4">No orders found</div>
        </div>
    )
}

