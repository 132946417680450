import { FETCH_GAINERS, FETCH_LOOSERS } from "./actionTypes";
let initialState = {
  gainers: {
    "NFO": [],
    "MCX": [],
    "CDS": [],
    "CRYPTO": [],
    "FX": [],
    "US": []
  },
  loosers: {
    "NFO": [],
    "MCX": [],
    "CDS": [],
    "CRYPTO": [],
    "FX": [],
    "US": []
  }
};
const market = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAINERS:
      return {
        ...state,
        gainers: action.payload
      };
    case FETCH_LOOSERS:
      return {
        ...state,
        loosers: action.payload
      };
    // case FETCH_OWNER:
    //   return {
    //     ...state,
    //     owner: action.payload,
    //   };
    default:
      return state;
  }
};
export default market;
