import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function Onboarding() {
    const history = useHistory();

    return <>
        <div className="screen">
            <div className="main-content bg-white">
                <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <img className="mb-5" src="images/onboarding.svg" alt="logo" />
                    <p className="small-title">Discover Your Trading Here</p>
                    <p className="onboarding-text text">Welcome to our Trading App! Start your journey to financial success with us today!</p>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                        <Button className="signin-btn" onClick={() => { history.push("/login") }}>Sign In</Button>
                        <Button className="signup-btn" onClick={() => { history.push("/signup") }}>Sign Up</Button>
                    </div>
                </div>
            </div>
        </div>

    </>
}