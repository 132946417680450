import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../../../store/bill/action";
import { convertDateFormatWithSpaces } from "../../../helper/general_helper";
import { FETCH_BILL_PDF_URL } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

export default function BillHistory() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const billState = useSelector((state) => state.bill);
  useEffect(() => {
    dispatch(fetchData(loginState.jwttoken))
  }, [])

  function fetchPdf(bill) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      api.get(FETCH_BILL_PDF_URL, {
        params: {
          last_bill_date: bill.generaton_date,
          second_last_bill_date: bill.previous_date
        },
        responseType: 'arraybuffer' // Ensure the response is treated as binary data
      })
        .then(function (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        })
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="screen">
      <div className="main-content bg-light">
        <span class="d-flex align-items-center fw-semibold gap-3 mb-30" onClick={() => { history.goBack() }}>
          <img src="images/left-arrow.svg" alt="left-arrow" />Bill History
        </span>
        {billState.history.map((history) => {
          return <>
            <Card className="border-0 p-0 mb-2 w-100" onClick={() => { fetchPdf(history) }}>
              <Card.Body className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src="images/pdf.svg" alt="pdf-icon" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="d-block font-m mb-0 lh-1">Bill PDF - {history.trades_count} Trades</span>
                      <span className="font-s font-dark-grey">{convertDateFormatWithSpaces(history.previous_date)} to {convertDateFormatWithSpaces(history.generaton_date)}</span>
                    </div>
                  </div>
                  <img src="images/download-icon.svg" alt="download-icon" />
                </div>


              </Card.Body>
            </Card>
          </>
        })}

        {/*<Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card>
        <Card className="border-0 p-0 mb-2 w-100">
          <Card.Body className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="images/pdf.svg" alt="pdf-icon" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <span className="d-block font-m mb-0 lh-1">NATURALGASM</span>
                  <span className="font-s font-dark-grey">04 Sep 23 to 11 Sep 23</span>
                </div>
              </div>
              <img src="images/download-icon.svg" alt="download-icon" />
            </div>


          </Card.Body>
        </Card> */}
      </div>
    </div>
  );
};


